<template>
  <div class="overall">
    <div class="title">
      <h2>Overall</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :inline="true" :model="filter">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Source" label-width="2.4rem">
                  <group-select
                    v-model="filter.sourceIds"
                    :data-source="sourcesList"
                    multiple
                    :loading="sourceLoading"
                    clearable
                    reserve-keyword
                    filterable
                    placeholder="请选择"
                  />
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Source Status" label-width="2.4rem">
                  <el-select v-model="filter.status" style="width:2.6rem;">
                    <el-option
                      v-for="item in sourceStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Source PayoutType" label-width="3.3rem">
                  <el-select v-model="filter.payoutType" clearable style="width:2rem;">
                    <el-option
                      v-for="item in payoutTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="Source Name" label-width="2.4rem">
                  <el-input
                    v-model="filter.sourceName"
                    style="width:3rem;"
                    placeholder="chestnut"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label=" " label-width="0.6rem">
                  <el-button type="primary" style="width:2rem" @click="searchListClick(1)"
                    >Search
                  </el-button>
                  <el-button
                    type="primary"
                    style="width:2rem"
                    icon="el-icon-plus"
                    @click="createClick"
                    >Create
                  </el-button>
                </el-form-item>
              </el-col>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              v-loading="listLoading"
              border
              stripe
              highlight-current-row
              :data="dataList"
              style="width:100%"
              height="70vmin"
            >
              <el-table-column label="Id" prop="id" align="center" fixed="left">
                <template slot-scope="scope">
                  <span
                    @click="detailClick(scope.row)"
                    v-bind:class="{ cor337ab7: scope.row.noRedirect !== 1 }"
                    >{{ scope.row.id }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Name"
                prop="company"
                align="center"
                min-width="110"
                fixed="left"
              >
                <template slot-scope="scope">
                  <span
                    @click="detailClick(scope.row)"
                    v-bind:class="{ cor337ab7: scope.row.noRedirect !== 1 }"
                    >{{ scope.row.company }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="Email"
                prop="email"
                align="center"
                show-overflow-tooltip
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="SignName"
                prop="signName"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="Status" prop="enable" align="center"></el-table-column>
              <el-table-column
                label="Whitelist Limited"
                prop="whitelistLimited"
                align="center"
                min-width="140"
              ></el-table-column>
              <el-table-column
                label="Weightiness"
                prop="weightiness"
                align="center"
                min-width="105"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.weightiness <= 5">只测一次单</span>
                  <span v-else-if="scope.row.weightiness >= 6">正常测单</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Description"
                prop="description"
                align="center"
                min-width="100"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="TimeZone"
                prop="timeZone"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="Traffic Type"
                prop="trafficType"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="Traffic Tag"
                prop="trafficTag"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="SyncUrl" min-width="140" align="center" show-overflow-tooltip>
                <template slot-scope="scope">
                  <a style="color:#606266;" target="_blank" :href="scope.row.syncUrl"
                    ><span>{{ scope.row.syncUrl }}</span></a
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="AsyncTag"
                min-width="140"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.asyncTag === 0">关闭</span>
                  <span v-else-if="scope.row.asyncTag === 1">开启</span>
                </template>
              </el-table-column>
              <el-table-column
                label="AffAutoBlock"
                min-width="140"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.affAutoBlock === 0">关闭</span>
                  <span v-else-if="scope.row.affAutoBlock === 1">开启</span>
                </template>
              </el-table-column>
              <el-table-column
                label="PayoutType"
                prop="payoutType"
                min-width="120"
              ></el-table-column>
              <el-table-column
                label="MaxPullOffer"
                prop="pullOfferMaxRecord"
                min-width="120"
              ></el-table-column>
              <el-table-column
                label="Account Name"
                prop="createBy"
                min-width="120"
              ></el-table-column>
              <el-table-column
                label="Time"
                prop="createTime"
                align="center"
                min-width="100"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column label="Action" align="center" min-width="200">
                <template slot-scope="scope">
                  <el-button type="primary" size="small" @click="updateClick(scope.row)"
                    >Update
                  </el-button>
                  <el-button @click="openSourceUser(scope.row)" size="small">UserSource</el-button>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageSize"
              :currentPage="currentPage"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>

    <!-- 添加模块框 -->
    <el-dialog
      :title="dialogType === 'edit' ? 'Source Update' : 'Source Create'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="closemoda"
      append-to-body
    >
      <el-form
        v-if="dialogType === 'new'"
        label-position="left"
        label-width="110px"
        :model="source"
        ref="newForm"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="SourceName">
              <el-input v-model="source.company" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Weightiness">
              <el-input v-model="source.weightiness" placeholder="1~10"></el-input>
              <el-tag type="warning">权重低于等于5每日只测一次单</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Description">
              <el-input v-model="source.description" placeholder></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="TimeZone">
              <el-input v-model="source.timeZone" placeholder></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Traffic Type">
              <el-select v-model="source.trafficType" placeholder="请选择">
                <el-option
                  v-for="item in trafficTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="source.trafficTag"
                multiple
                clearable
                filterable
                placeholder="status"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Payout Type">
              <el-select v-model="source.payoutType" placeholder="请选择">
                <el-option
                  v-for="item in payoutType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Authorised:" prop="bindAccountId" required>
              <el-select v-model="source.bindAccountId" clearable filterable>
                <el-option
                  v-for="item in bindAccountList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Async Tag" label-width="96px">
              <el-select v-model="source.asyncTag" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Aff Auto Block" label-width="110px">
              <el-select v-model="source.affAutoBlock" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        v-if="dialogType === 'edit'"
        label-position="left"
        :model="source"
        label-width="110px"
      >
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Name" label-width="90px">
              <el-input v-model="source.company" placeholder="please add name..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Weightiness">
              <el-input v-model="source.weightiness" placeholder="1~10"></el-input>
              <el-tag type="warning">权重低于等于5每日只测一次单</el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Description">
              <el-input v-model="source.description" placeholder="please add desc..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Status" label-width="90px">
              <el-select v-model="source.enable" style="width:100%">
                <el-option
                  v-for="item in sourceUpdateStaatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Payout Type" label-width="96px">
              <el-select v-model="source.payoutType" style="width:100%">
                <el-option
                  v-for="item in payoutType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Traffic Type" label-width="96px">
              <el-select v-model="source.trafficType" style="width:100%">
                <el-option
                  v-for="item in trafficTypes"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Traffic Tag:" prop="trafficTag">
              <el-select
                v-model="source.trafficTag"
                multiple
                clearable
                filterable
                placeholder="请选择"
                style="width:100%"
              >
                <el-option
                  v-for="item in tagOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="SignName">
              <el-input v-model="source.signName" placeholder="signName here"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="SyncUrl">
              <el-input v-model="source.syncUrl" placeholder="syncUrl here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="TimeZone" label-width="90px">
              <el-input
                v-model="source.timeZone"
                placeholder="eg: 8 or -8,(east tz[0~12],west tz[-1 ~ -12])"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Email">
              <el-input v-model="source.email" placeholder="please add email..."></el-input>
            </el-form-item>
          </el-col>
          <el-col :lg="11" :md="20" :offset="1">
            <el-form-item label="Async Tag" label-width="96px">
              <el-select v-model="source.asyncTag" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :lg="11" :md="20">
            <el-form-item label="Aff Auto Block" label-width="110px">
              <el-select v-model="source.affAutoBlock" style="width:100%">
                <el-option
                  v-for="item in numberSwitchOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitClick">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="userDialogVisible"
      :close-on-click-modal="false"
      :append-to-body="true"
    >
      <el-transfer
        filterable
        :titles="['Authorizable', 'Authorised']"
        v-model="sourceUserList"
        :data="allUser"
      ></el-transfer>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="authSourceUser()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    getSourceList,
    getSourceUserList,
    insertSource,
    updateSource,
    updateSourceUser,
  } from 'api/source/overall';
  import { getBindAccountListApi } from 'api/account/index';
  import { getUserList } from 'api/user';
  import { myMixin } from '@/mixins/mixins.js';
  import Pagination from '@/components/pagination';
  import { mapActions, mapState } from 'vuex';
  import { cloneDeep } from 'lodash-es';
  import GroupSelect from '@/components/GroupSelect';
  import { getAllTags } from '@/api/affiliate/tag';

  let clickTimer = null;

  const defaultSource = {
    sourceName: '',
    weightiness: '',
    description: '',
    trafficType: '',
    payoutType: 'CPI',
    timeZone: '',
    trafficTag: [],
    bindAccountId: '',
    asyncTag: 1,
    affAutoBlock: 0,
  };
  export default {
    name: 'SourceOverall',
    mixins: [myMixin],
    components: {
      Pagination,
      GroupSelect,
    },
    data() {
      return {
        pageSize: 20,
        currentPage: 1,
        filter: {
          sourceIds: null,
          sourceName: null,
          payoutType: null,
          status: 'enable',
        },
        source: Object.assign({}, defaultSource),
        addWhitelistLimited: [
          {
            label: 'YES',
            value: 'YES',
          },
          {
            label: 'NO',
            value: 'NO',
          },
        ],
        listLoading: false,
        total: null,

        trafficTypes: [
          {
            label: 'Incent',
            value: '20',
          },
          {
            label: 'Network',
            value: '30',
          },
          {
            label: 'In-app',
            value: '40',
          },
          {
            label: 'Direct',
            value: '50',
          },
        ],
        payoutType: [
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
        ],
        sourceStatus: [
          {
            label: 'enable',
            value: 'enable',
          },
          {
            label: 'disable',
            value: 'disable',
          },
          {
            label: 'pending',
            value: 'pending',
          },
          {
            label: 'reject',
            value: 'reject',
          },
          {
            label: 'prepare',
            value: 'prepare',
          },
        ],
        sourceUpdateStaatus: [
          {
            label: 'ENABLE',
            value: 'ENABLE',
          },
          {
            label: 'Disable',
            value: 'DISABLE',
          },
          {
            label: 'Pending',
            value: 'PENDING',
          },
          {
            label: 'Reject',
            value: 'REJECT',
          },
          {
            label: 'Prepare(稍后开启)',
            value: 'PREPARE',
          },
        ],
        bindAccountList: [],
        closemoda: false,
        dialogType: 'new',
        dialogVisible: false,
        sourceaddFlag: false,
        payoutTypes: [
          {
            label: 'All',
            value: '',
          },
          {
            label: 'CPI',
            value: 'CPI',
          },
          {
            label: 'CPA',
            value: 'CPA',
          },
          {
            label: 'CPL',
            value: 'CPL',
          },
          {
            label: 'SmartLink',
            value: 'SmartLink',
          },
        ],
        dataList: [],
        tagOption: [],
        userDialogVisible: false,
        sourceUserList: [],
        allUser: [],
        sourceId: '',
        numberSwitchOptions: [
          {
            value: 0,
            label: '关闭',
          },
          {
            value: 1,
            label: '开启',
          },
        ],
      };
    },
    computed: {
      ...mapState('source', {
        sourcesList: (state) => state.sourcesList,
        sourceLoading: (state) => state.sourceLoading,
      }),
    },
    mounted() {
      this.searchListClick();
      this.getSourceIdsList();
      this.getTagList();
      this.getBindAccountList();
    },
    methods: {
      ...mapActions('source', ['getSourceIdsList']),
      searchListClick(curPage) {
        this.listLoading = true;
        if (curPage) {
          this.currentPage = curPage;
        }
        let param = {
          ...this.filter,
          page: this.currentPage,
          pageSize: this.pageSize,
        };
        if (param.sourceIds && Array.isArray(param.sourceIds)) {
          param.sourceIds = param.sourceIds.join(',');
        }
        getSourceList(param).then((response) => {
          this.listLoading = false;
          if (response.code === 200) {
            this.dataList = response.result;
            this.total = response.total;
          } else {
            this.$message.error(response.message);
          }
        });
      },
      async submitClick() {
        const params = { ...this.source };
        if (params.trafficTag) {
          params.trafficTag = params.trafficTag?.join(',');
        }
        if (this.dialogType === 'new') {
          try {
            await this.$refs.newForm.validate();
            var patrn = /^(([1-9](\.\d{2})?)|10|10.0)$/;
            if (!patrn.test(this.source.weightiness)) {
              this.$message.error('Weightiness只限于1到10~');
              return;
            }

            insertSource(params).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Add Success',
                  type: 'success',
                });
                this.searchListClick();
                this.dialogVisible = false;
              } else {
                this.$message.error('Add Error:' + response.message);
              }
            });
          } catch (error) {
            console.log(error);
          }
        } else {
          updateSource(params)
            .then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Update Success',
                  type: 'success',
                });
                this.searchListClick();
              } else {
                this.$message.error('Update Error:' + response.message);
              }
            })
            .catch((err) => {
              this.$message.error('Update Error:' + err);
            });
          this.dialogVisible = false;
        }
        this.sourceaddFlag = false;
      },
      updateClick(row) {
        const source = cloneDeep(row);
        source.sourceName = source.company;
        source.trafficTag = source.trafficTag ? source.trafficTag.split(',') : [];
        this.source = source;
        this.dialogType = 'edit';
        this.dialogVisible = true;
      },
      createClick() {
        this.source = Object.assign({}, defaultSource);
        this.dialogType = 'new';
        this.dialogVisible = true;
      },
      handleSizeChange(val) {
        this.pageSize = val;
        this.handleCurrentChange(1);
      },
      handleCurrentChange(val) {
        this.currentPage = val;
        this.searchListClick();
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        if (row.noRedirect === 1) {
          return false;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/source/detail',
              query: {
                sourceId: row.id,
                sourceName: row.company,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      getTagList() {
        getAllTags('offer')
          .then((resp) => {
            if (resp.code === 200) {
              this.tagOption = resp.result?.map((item) => ({
                label: item.trafficTag,
                value: item.trafficTag,
              }));
            } else {
              this.tagOption = [];
            }
          })
          .catch(() => {
            this.tagOption = [];
          });
      },
      getBindAccountList() {
        console.log('ddd', getBindAccountListApi);
        getBindAccountListApi()
          .then((resp) => {
            if (resp.code === 200) {
              this.bindAccountList = resp.result?.map((item) => ({
                label: item.uname,
                value: item.id,
              }));
            } else {
              this.bindAccountList = [];
            }
          })
          .catch(() => {
            this.bindAccountList = [];
          });
      },
      async getAllUser() {
        const { code, result = [] } = await getUserList();
        if (code) {
          this.allUser = result.map((item) => ({
            key: item.id,
            label: item.uname,
            title: item.uname,
          }));
        }
      },
      async getSourceUsers(sourceId) {
        const { code, result = [] } = await getSourceUserList(sourceId);
        if (code === 200) {
          this.sourceUserList = result.map((item) => item.id);
        } else {
          this.$message.error('Failed to get authorized list');
        }
      },
      openSourceUser(row) {
        this.sourceId = row.id;
        this.getAllUser();
        this.getSourceUsers(row.id);
        this.userDialogVisible = true;
      },
      async authSourceUser() {
        const params = {
          sourceId: this.sourceId,
          accountList: this.sourceUserList,
        };
        const { code, message } = await updateSourceUser(params);
        if (code === 200) {
          this.$message.success({
            message: 'Update Success',
            type: 'success',
          });
          this.userDialogVisible = false;
        } else {
          this.$message.error('Update Error:' + message);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  ::v-deep .el-transfer {
    display: flex;
    justify-content: center;
    align-items: center;

    .el-transfer-panel {
      width: 288px !important;
    }
  }
</style>
