import request from 'utils/request';

const api = {
  SOURCE_LIST: '/api/source/overall/list',
  SOURCE_ADD: '/api/source/overall/add',
  SOURCE_UPDATE: '/api/source/overall/update',
  SOURCE_USER_LIST: '/api/source/overall/{sourceId}/account',
  UPDATE_SOURCE_USER: '/api/source/overall/updateSourceAccount',
};
export function getSourceList(query) {
  return request({
    url: api.SOURCE_LIST,
    method: 'get',
    params: query,
  });
}
export function insertSource(data) {
  return request({
    url: api.SOURCE_ADD,
    method: 'post',
    data,
  });
}
export function updateSource(data) {
  return request({
    url: api.SOURCE_UPDATE,
    method: 'put',
    data,
  });
}

export function getSourceUserList(sourceId) {
  const url = api.SOURCE_USER_LIST.replace('{sourceId}', sourceId);
  return request({
    url,
    method: 'get',
  });
}

export function updateSourceUser(data) {
  return request({
    url: api.UPDATE_SOURCE_USER,
    method: 'put',
    data,
  });
}
