import request from 'utils/request';

const api = {
  ACCOUNT_LIST: '/api/account/list',
  ACCOUNT_ADD: '/api/account/add',
  ACCOUNT_UPDATE: '/api/account/update',
  ACCOUNT_DELETE: '/api/account/delete',
  ACCOUNT_ROLE_LIST: '/api/account/role/list',
  ACCOUNT_ROLE_UPDATE: '/api/account/accountId/role/update',
  ACCOUNT_BIND_LIST: '/api/source/overall/listBindAccount',
};
/**
 * 查询用户列表
 * @param {*} query {accountId:int,accountName:string,page:int,pageSize:int}
 */
export function getAccountList(query) {
  return request({
    url: api.ACCOUNT_LIST,
    method: 'get',
    params: query,
  });
}
/**
 * 新增用户
 * @param {*} data {username:int,password:int,description:string,roles:Array[int],isOpenAllAdv:int,isOpenAllAff:int}
 */
export function insertAccount(data) {
  return request({
    url: api.ACCOUNT_ADD,
    method: 'post',
    data,
  });
}

/**
 * 更新用户
 * @param {*} data {id:int,description:string,isOpenAllAdv:int,isOpenAllAff:int}
 */
export function updateAccount(data) {
  return request({
    url: api.ACCOUNT_UPDATE,
    method: 'put',
    data,
  });
}
/**
 * 删除用户
 * @param {*} id 用户id
 */
export function deleteAccount(id) {
  return request({
    url: api.ACCOUNT_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}
/**
 * 获取角色列表
 *
 */
export function getRoleList() {
  return request({
    url: api.ACCOUNT_ROLE_LIST,
    method: 'get',
  });
}
/**
 * 用户绑定角色
 * @param {*} accountId 用户id
 * @param {*} roleIds 角色id（多角色以','相隔）
 */
export function bindRole(accountId, roleIds) {
  const url = api.ACCOUNT_ROLE_UPDATE.replace('accountId', accountId);
  return request({
    url,
    method: 'put',
    data: {
      roleIds,
    },
  });
}

export function getBindAccountListApi() {
  const url = api.ACCOUNT_BIND_LIST;
  return request({
    url,
    method: 'get',
  });
}
